import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/Projetos/ortoapp/frontend/support/doctor_support/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "como-que-eu-faço"
    }}>{`Como que eu faço?`}</h1>
    <h2 {...{
      "id": "como-cancelar-consultas"
    }}>{`Como cancelar consultas`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=Pm6k4hRgwLQ"
      }}><img src="https://img.youtube.com/vi/Pm6k4hRgwLQ/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "como-confirmar-consultas"
    }}>{`Como confirmar consultas`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=pphCNfWls-w"
      }}><img src="https://img.youtube.com/vi/pphCNfWls-w/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "como-agendar-uma-consulta-presencial"
    }}>{`Como agendar uma consulta presencial`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=9vETnDuNEag"
      }}><img src="https://img.youtube.com/vi/9vETnDuNEag/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "como-agendar-uma-conversa-virtual"
    }}>{`Como agendar uma conversa virtual`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=BJbxUuT6XQE"
      }}><img src="https://img.youtube.com/vi/BJbxUuT6XQE/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "convidando-médico"
    }}>{`Convidando médico`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=AKBoSVKOSEE"
      }}><img src="https://img.youtube.com/vi/AKBoSVKOSEE/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "como-editar-seu-perfil"
    }}>{`Como editar seu perfil`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=OQEn_sreaxw"
      }}><img src="https://img.youtube.com/vi/OQEn_sreaxw/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "habilitando-notificações"
    }}>{`Habilitando notificações`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=xk4pHzAJcG0"
      }}><img src="https://img.youtube.com/vi/xk4pHzAJcG0/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "como-adicionar-novos-convênios"
    }}>{`Como adicionar novos convênios`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=yaOqiqG6mdc"
      }}><img src="https://img.youtube.com/vi/yaOqiqG6mdc/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "perguntas-frequentes"
    }}>{`Perguntas frequentes`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=TfIhgXrCoq0"
      }}><img src="https://img.youtube.com/vi/TfIhgXrCoq0/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "respostas-rápidas"
    }}>{`Respostas rápidas`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=HSVbADyaVG4"
      }}><img src="https://img.youtube.com/vi/HSVbADyaVG4/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "introdução-udok-médicos"
    }}>{`Introdução Udok Médicos`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=aEXtoQXjSo4"
      }}><img src="https://img.youtube.com/vi/aEXtoQXjSo4/0.jpg" width="300" /></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      